import React from 'react';
import styled from 'styled-components';

import Song from './Song';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
`;

const SongList = ({ songs }) => (
  <Wrapper>{songs.map((song, index) => <Song key={song.song_title} index={index + 1} song={song} />)}</Wrapper>
);

export default SongList;
