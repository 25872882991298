import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, DropdownItem, DropdownMenu } from 'styled-bootstrap-components';

import theme from '../../styled/theme';
import { ServiceLink } from '../../styled';

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  min-width: 25px;
  margin-right: 0.25rem;
`;

class ServiceButton extends Component {
  state = {
    hidden: true,
  }

  handleOpenCloseDropdown = () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  }

  handleCloseDropdown = () => {
    if (this.state.hidden) {
      return;
    }

    this.setState({
      hidden: true,
    })
  }

  render() {
    return (
      <Dropdown>
        <Button bg={this.props.bg} color={this.props.color} border={this.props.border} borderColor={this.props.borderColor} lg onClick={this.handleOpenCloseDropdown}>
          {this.props.children}
        </Button>
        <DropdownMenu hidden={this.state.hidden}>
          {this.props.services && this.props.services.map(platform => (
            <DropdownItem>
              <ServiceLink href={platform.url} target="_blank" rel="noopener nofollow" color="black" textTransform="none">
                <Icon icon={['fab', platform.platform === 'itunes' ? 'itunes-note' : platform.platform]} size="lg" />&nbsp;
                {platform.platform}
              </ServiceLink>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
};

// Service.propTypes = {
//   color: PropTypes.string.isRequired,
// };

export default ServiceButton;
