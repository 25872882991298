import React from 'react';
import styled from 'styled-components';
import theme from '../../styled/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.grayMid};
  padding-bottom: 1rem;

  div {
    margin: 0 0.75rem;
  }

  span {
    color: ${theme.colors.gray};
    font-size: 0.875rem;
  }
`;

const Song = ({ song, index }) => (
  <Wrapper>
    <span>{index}</span>
    <div>{song.song_title}</div>
  </Wrapper>
);

export default Song;
