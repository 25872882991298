import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Column, Button, Dropdown, DropdownItem, DropdownMenu } from 'styled-bootstrap-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
import SongList from '../components/SongList';
import ServiceButton from '../components/Album/ServiceButton';
import { PageHeader, FlexRow, Text } from '../styled';

const Album = props => {
  const { data } = props;

  return (
    <Layout location={props.location}>
      <Helmet title={`${data.wordpressWpAlbum.title} - Eddy Herrera`} />
      <PageHeader>
        <Container>
          <Row>
            <Column sm={12} mb="1.5rem">
              <Text color="gray">
                <Link to="/discografia">&larr; Volver a la discografía</Link>
              </Text>
            </Column>
            <Column sm={4}>
              <Img resolutions={data.wordpressWpAlbum.featured_media.localFile.childImageSharp.resolutions} />
            </Column>
            <Column sm={8}>
              <Text color="gray">{data.wordpressWpAlbum.acf.release_date}</Text>
              <h1>{data.wordpressWpAlbum.title}</h1>
              <FlexRow>
                <ServiceButton services={data.wordpressWpAlbum.acf.streaming_platforms} bg="primary" color="white">
                  <FontAwesomeIcon icon={faPlay} />&nbsp; Reproducir
                </ServiceButton>
                &nbsp;&nbsp;&nbsp;
                <ServiceButton
                  services={data.wordpressWpAlbum.acf.purchase_platforms}
                  bg="transparent"
                  color="primary"
                  border="2px solid"
                  borderColor="primary"
                >
                  <FontAwesomeIcon icon={faShoppingCart} />&nbsp; Comprar
                </ServiceButton>
              </FlexRow>
            </Column>
          </Row>
        </Container>
      </PageHeader>
      <div className="home-banner">
        <Container>
          <div className="section-title mb-5" />
          <div dangerouslySetInnerHTML={{ __html: data.wordpressWpAlbum.content }} />
          {data.wordpressWpAlbum.acf.songs && (
            <div>
              <h2>Lista de Canciones</h2>
              <Row>
                <Column sm={10}>
                  <SongList songs={data.wordpressWpAlbum.acf.songs} />
                </Column>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AlbumQuery($slug: String!) {
    wordpressWpAlbum(slug: { eq: $slug }) {
      title
      slug
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            resolutions(width: 335, height: 335) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
      acf {
        release_date(formatString: "YYYY")
        streaming_platforms {
          platform
          url
        }
        purchase_platforms {
          platform
          url
        }
        songs {
          song_title
          featuring_artist
          featured_artist_name
        }
      }
    }
  }
`;

export default Album;
